/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'

import Wrapper from '../Wrapper'

const StyledFooter = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.colors.jet};
    bottom: 0;
    max-height: 430px;
    padding: 65px 20px;
    position: absolute;
    text-align: center;
    width: 100%;

    ${theme.queries.medium} {
      max-height: 125px;
      padding-bottom: ${theme.gutters.extraLarge}px;
      padding-top: ${theme.gutters.extraLarge}px;
    }
  `};
`

const Links = styled.ul`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    text-transform: uppercase;

    li:hover {
      text-decoration: underline;
    }

    ${theme.queries.small} {
      font-size: 14px;
      margin-top: 65px;

      li + li {
        margin-top: 50px;
      }
    }

    ${theme.queries.medium} {
      align-items: center;
      display: flex;
      font-size: 14px;
      justify-content: space-between;

      li + li {
        margin-left: 20px;
      }
    }
  `}
`

const SocialMedia = styled.ul`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;

    li {
      cursor: pointer;
      height: 43px;
      width: 43px;
    }

    ${theme.queries.small} {
      align-self: center;
      margin-top: 61px;
      width: 70vw;
    }

    ${theme.queries.medium} {
      width: 105px;

      li {
        height: 30px;
        width: 30px;
      }
    }
  `}
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-footer.png" }) {
        childImageSharp {
          fluid(maxWidth: 206) {
            ...GatsbyImageSharpFluid
          }
          # fixed(width: 206, height: 65) {
          #   ...GatsbyImageSharpFixed
          # }
        }
      }
      facebook: file(relativePath: { eq: "ic-facebook.png" }) {
        childImageSharp {
          fluid(maxWidth: 45) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter: file(relativePath: { eq: "ic-twitter.png" }) {
        childImageSharp {
          fluid(maxWidth: 45) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linkedin: file(relativePath: { eq: "ic-linkedin.png" }) {
        childImageSharp {
          fluid(maxWidth: 45) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledFooter className="main-footer">
      <Wrapper>
        <div
          css={theme => css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            ${theme.queries.medium} {
              flex-direction: row;
              align-items: center;
            }
          `}
        >
          <Link to="/">
            <Img
              fadeIn={false}
              alt="Denario Logo"
              fluid={data.logo.childImageSharp.fluid}
              css={theme => css`
                ${theme.queries.small} {
                  align-self: center;
                  height: 50px;
                  width: 159px;
                }
                ${theme.queries.medium} {
                  height: 65px;
                  width: 206px;
                }
              `}
            />
          </Link>

          <Links>
            <li>
              <Link to="/termos">
                <FormattedMessage id="terms_and_conditions" />
              </Link>
            </li>
            <li>
              <Link to="/privacidade">
                <FormattedMessage id="privacy_policy" />
              </Link>
            </li>
          </Links>

          <SocialMedia>
            <li>
              <a
                href="https://www.facebook.com/denarioapp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.facebook.childImageSharp.fluid} />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/AppDenario"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.twitter.childImageSharp.fluid} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/denarioapp/"
                target="blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.linkedin.childImageSharp.fluid} />
              </a>
            </li>
          </SocialMedia>
        </div>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
