import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useIntl } from 'gatsby-plugin-intl'
import * as GeolocationAction from '../../store/modules/geolocation/actions'
import { setCurrency } from '../../store/modules/donation/actions'
import { currencies } from '../../utils/money'

const Geolocation = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const geolocation = useSelector(state => state.geolocation)

  const setCoordinates = ({ coords }) => {
    dispatch(GeolocationAction.update(coords.latitude, coords.longitude))
  }

  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.error(
        intl.formatMessage({ id: 'toast_messages.error.geolocation' })
      )
      return
    }
    if (geolocation.hasCoordinates) {
      return
    }
    navigator.geolocation.getCurrentPosition(setCoordinates)
  }

  getLocation()

  dispatch(setCurrency(currencies.BRL))

  return null
}

export default Geolocation
