import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const Container = styled.div``

export const ChooseLang = styled.button`
  ${({ disabled }) => css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    span {
      margin-right: 10px;
      margin-top: -3px;
    }

    img {
      margin-right: 10px;
    }

    ${disabled &&
      css`
        color: inherit;
        cursor: default;
      `}
  `}
`

export const Item = styled.li`
  ${({ theme, isSelected }) => css`
    color: ${theme.colors.font};
    font-size: 18px;
    margin: 10px 0;
    width: 100%;

    ${isSelected &&
      css`
        button {
          font-weight: bold;
        }
      `}
  `}
`

export const LanguageFlag = styled.button`
  ${({ theme }) => css`
    width: 29px;
    height: 29px;
    overflow: hidden;
    padding: 1px;
    border: 1px solid ${theme.colors.font};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;

    span {
      position: absolute !important;
      top: -10px;
      left: -6px;
      right: -5px;
      bottom: -10px;
      height: auto !important;
      width: 143% !important;
    }

    ${theme.queries.small} {
      border: 2px solid;
    }
  `}
`

export const Submenu = styled.ul`
  ${({ theme, active }) => css`
    background-color: white;
    box-shadow: 0px 2px 10px #7c7c7c80;
    border-radius: 10px;
    opacity: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    list-style: none;

    margin: 20px -25px 0;
    min-width: 200px;
    padding: 10px 30px;

    height: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 10;

    transition: ease-in 200ms;

    ${active &&
      `
        height: auto;
        opacity: 1;
        padding: 10px 30px;
      `}

    ${theme.queries.small} {
      box-shadow: none;
      display: block;
      height: auto;
      margin-bottom: 0;
      position: absolute;
      padding: 15px 25px;

      ${active &&
        `
          height: auto;
          opacity: 1;
          padding: 15px 25px;
      `}

      li {
        margin: 0;
        &:last-child {
          padding-bottom: 0;
          margin-top: 25px;
        }
      }
    }
  `}
`

export const LangContainer = styled.div`
  position: relative;
`
