/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'

const move = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
`

const ScrollArrow = () => {
  const [showArrow, setShowArrow] = useState(1)

  const scrollAction = () => {
    const $html = document.querySelector('html')
    const topWindow = $html.scrollTop * 8
    const windowHeight = $html.scrollHeight
    let position = topWindow / windowHeight
    position = 1 - position

    setShowArrow(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollAction, false)

    return () => {
      window.removeEventListener('scroll', scrollAction, false)
    }
  }, [])

  return (
    <Arrow showArrow={showArrow}>
      <span />
    </Arrow>
  )
}

const Arrow = styled.span`
  ${({ theme, showArrow }) => css`
    animation: ${move} 1s infinite alternate;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid ${theme.colors.darkGrey};
    border-radius: 20px;
    bottom: 15px;
    display: flex;
    height: 40px;
    justify-content: center;
    left: calc(50% - 20px);
    opacity: ${showArrow};
    padding-bottom: 5px;
    position: fixed;
    z-index: 3;
    width: 40px;

    span {
      border: solid ${theme.colors.darkGrey};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  `}
`

export default ScrollArrow
