import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const Container = styled.header`
  ${({ theme }) => css`
    background-color: ${theme.colors.transparent};
    height: 160px;
    width: 100%;
    align-items: center;
    display: flex;
    z-index: 1;
    display: fixed;

    ${theme.queries.small} {
      height: 60px;
      margin-bottom: 20px;
    }
  `}
`

export const NavBar = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
`

export const Nav = styled.div`
  ${({ theme }) => css`
    ${theme.queries.small} {
      display: none !important;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;

    hr {
      border-left: 1px solid #c1c1c1;
      height: 25px;
      margin: 0 50px;
      width: 0px;
    }
  `}
`

export const Pages = styled.ul`
  ${({ theme }) => css`
    color: ${theme.colors.arsenic};
    display: flex;
    text-transform: none;

    ${theme.queries.medium} {
      align-items: center;
      li + li {
        margin-left: 29px;
      }
    }
  `}
`

export const Actions = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;

  li + li {
    margin-left: 20px;
  }
`

export const UserOptions = styled.button`
  ${({ theme }) => css`
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    outline: 0;

    svg {
      margin-left: 15px;
      path {
        fill: ${theme.colors.celestialblue};
      }
    }
  `}
`

export const NavMenuDesktop = styled.ul`
  ${({ theme }) => css`
    background: #ffffff;
    box-shadow: 0px 2px 10px #7c7c7c80;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 145px;
    right: 0;
    opacity: 1;
    position: absolute;
    top: 32px;
    transition: height 0.2s, opacity 0.2s;
    width: 165px;
    z-index: 1;

    li {
      align-items: center;
      color: ${theme.colors.arsenic};
      cursor: pointer;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      list-style: none;
      padding-left: 25px;
      height: 100%;
      text-align: left;
      width: 100%;

      & + li {
        border-top: 1px solid #e0e0e0;
        margin: 0;
      }

      button {
        background: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        font: inherit;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 100%;
        height: 100%;
        outline: 0;
      }
    }
  `}
`

export const NavMobile = styled.div`
  ${({ theme }) => css`
    & > button {
      background: transparent;
      border: 0;
      outline: 0;
      -webkit-appearance: none;
    }

    ${theme.queries.medium} {
      display: none !important;
    }
    ${theme.queries.small} {
      order: 1;

      & > button {
        display: flex;
        align-items: center;
      }
    }
  `}
`

export const NavMenuMobile = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 23px 36px #8aa6d839;
  height: auto;
  width: 90%;
  position: absolute;
  padding: 43px 46px 35px;
  top: 55px;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: height 0.3s, opacity 0.3s, padding 0.4s;
  z-index: 1;

  ul {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-weight: 300;

    li + li {
      margin-top: 37px;
    }
  }

  hr {
    border: 0;
    border-top: 1px solid #e2e2e2;
    width: 100%;
    margin: 32px auto 28px;
  }

  button.logoff {
    background: transparent;
    border: 0;
    color: #c1c1c1;
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    margin: 5px 0 0;
    padding: 0;
    text-align: left;
    text-transform: uppercase;
    outline: 0;
  }
`
