/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'emotion-theming'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

// import '../../config/ReactotronConfig'
import { store, persistor } from '../../store'

import theme from './theme'
import fontStyles from './font-styles'
import resetStyles from './reset-styles'

import Header from '../Header'
import Footer from '../Footer'
import Geolocation from '../Geolocation'
import ScrollArrow from '../ScrollArrow'

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    min-height: 100vh;
    min-width: 320px;
    overflow-x: hidden;
    position: relative;
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    min-height: calc(100vh - 425px);
    padding-bottom: 425px;

    ${theme.queries.medium} {
      min-height: calc(100vh - 125px);
      padding-bottom: 125px;
    }
  `}
`

const Main = styled.main`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    height: 100%;
  `}
`

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Helmet>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Container>
              <Global styles={css(resetStyles, fontStyles)} />
              <ToastContainer autoClose={3000} />
              <Geolocation />
              <Content>
                <Header />
                <Main>
                  {children}
                  <ScrollArrow />
                </Main>
              </Content>
              <Footer />
            </Container>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
