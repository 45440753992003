export function setDonationAmount(amount) {
  return {
    type: '@donation/SET_AMOUNT',
    amount,
  }
}

export function resetDonationAmount() {
  return {
    type: '@donation/RESET_AMOUNT',
  }
}

export function setCurrency(currency) {
  return {
    type: '@donation/SET_CURRENCY',
    currency,
  }
}

export function setMinimumValue(value) {
  return {
    type: '@donation/SET_MINIMUM_VALUE',
    value,
  }
}

export function setRecipient({ type, institutionId, campaignId }) {
  return {
    type: '@donation/SET_RECIPIENT',
    payload: { institutionId, campaignId, type },
  }
}

export function setIsMaintenance(isMaintenance) {
  return {
    type: '@donation/SET_MAINTENACE',
    isMaintenance,
  }
}
