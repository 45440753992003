import { LANGUAGE } from './enum/language'

export const getSiteCurrentLanguage = ({ locale }) => {
  switch (locale) {
    case 'en':
      return LANGUAGE.english
    case 'pt':
    default:
      return LANGUAGE.portuguese
  }
}
