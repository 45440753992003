/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useRef, useState, useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useIntl, changeLocale } from 'gatsby-plugin-intl'
import { useOnClickOutside } from '../../../utils/hooks'
import { getSiteCurrentLanguage } from '../../../utils/getLanguage'
import { LANGUAGE } from '../../../utils/enum/language'

import {
  Container,
  Item,
  ChooseLang,
  LanguageFlag,
  Submenu,
  LangContainer,
} from './styles'

const ChooseLanguage = () => {
  const ref = useRef(null)
  const intl = useIntl()
  const [showLangs, toggleLangs] = useState(false)

  useOnClickOutside(ref, e => {
    if (!e.target.classList.contains('offclick')) {
      toggleLangs(false)
    }
  })

  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGE.portuguese)

  useEffect(() => {
    setCurrentLanguage(getSiteCurrentLanguage(intl))
  })

  const Langs = () => (
    <Container>
      <Item
        className="offclick"
        isSelected={currentLanguage === LANGUAGE.portuguese}
      >
        <ChooseLang
          className="offclick"
          disabled={currentLanguage === LANGUAGE.portuguese}
          onClick={() => {
            if (currentLanguage !== LANGUAGE.portuguese) {
              changeLocale('pt')
            }
          }}
        >
          <ReactCountryFlag
            countryCode="br"
            style={{ width: '20px', height: 'auto' }}
            svg
          />
          Português
        </ChooseLang>
      </Item>
      <Item
        className="offclick"
        isSelected={currentLanguage === LANGUAGE.english}
      >
        <ChooseLang
          className="offclick"
          onClick={() => {
            if (currentLanguage !== LANGUAGE.english) {
              changeLocale('en')
            }
          }}
        >
          <ReactCountryFlag
            countryCode="us"
            style={{ width: '20px', height: 'auto' }}
            svg
          />
          English
        </ChooseLang>
      </Item>
    </Container>
  )

  const CurrentFlag = () => (
    <>
      {getSiteCurrentLanguage(intl) === LANGUAGE.portuguese && (
        <ReactCountryFlag
          countryCode="br"
          style={{ width: '150%', height: 'auto' }}
          svg
        />
      )}
      {getSiteCurrentLanguage(intl) === LANGUAGE.english && (
        <ReactCountryFlag
          countryCode="us"
          style={{ width: '150%', height: 'auto' }}
          svg
        />
      )}
    </>
  )

  return (
    <LangContainer>
      <LanguageFlag onClick={() => toggleLangs(!showLangs)} ref={ref}>
        <CurrentFlag />
      </LanguageFlag>
      <Submenu active={showLangs}>
        <Langs />
      </Submenu>
    </LangContainer>
  )
}

export default ChooseLanguage
