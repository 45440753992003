/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, Link, navigate } from 'gatsby-plugin-intl'

import { useDispatch, useSelector } from 'react-redux'
import {
  logoutRequest,
  setCallbackRoute,
  resetLogin,
} from '../../store/modules/auth/actions'
import { setCampaignsListRequest } from '../../store/modules/campaign/actions'
import { setInstitutionsListRequest } from '../../store/modules/institution/actions'

import Wrapper from '../Wrapper'
import Button from '../Button'
import Text from '../Text'
import ChooseLanguage from './ChooseLanguage'

import Arrow from '../../images/icons/ic-arrow.svg'

import {
  Container,
  NavBar,
  Nav,
  NavMobile,
  NavMenuDesktop,
  NavMenuMobile,
  Pages,
  Actions,
  UserOptions,
} from './styles'

const activeLinkStyle = {
  color: '#4B99C1',
  fontWeight: 'bold',
}

const Header = () => {
  const dispatch = useDispatch()

  const logged = useSelector(state => state.auth.logged)
  const user = useSelector(state => state.user)
  const callbackRoute = useSelector(state => state.auth.callbackRoute)

  const [showNav, setShowNav] = useState(false)
  const [isCheckout, setCheckout] = useState(() => {
    if (typeof window === 'undefined') return false

    const isFromCheckout = callbackRoute.includes('checkout')

    const hasCheckoutInSetting = JSON.parse(
      sessionStorage.getItem('@denario_app/settings')
    )?.isCheckout

    return isFromCheckout || hasCheckoutInSetting
  })

  const handleCampaignsSelect = () => {
    dispatch(setCampaignsListRequest(null))
    navigate('/campanhas')
  }

  const handleInstitutionsSelect = () => {
    dispatch(setInstitutionsListRequest(null))
    navigate('/instituicoes')
  }

  const { pathname } = window.location
  const hookPath = pathname.substr(3, pathname.length)

  const handleLogin = () => {
    dispatch(resetLogin())
    dispatch(setCallbackRoute(hookPath))
  }

  const handleLogoff = () => {
    dispatch(logoutRequest())
  }

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 206) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hamburguer: file(relativePath: { eq: "menu-home-mobile.png" }) {
        childImageSharp {
          fixed(width: 21, height: 18) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container className="main-header">
      <Wrapper>
        <NavBar>
          <Link to="/">
            <Img
              loading="eager"
              fadeIn={false}
              alt="Denario Logo"
              fluid={data.logo.childImageSharp.fluid}
              css={theme => css`
                ${theme.queries.small} {
                  height: 28px;
                  width: 90px;
                }
                ${theme.queries.medium} {
                  height: 65px;
                  width: 206px;
                }
              `}
            />
          </Link>

          <Nav>
            {!isCheckout && (
              <Fragment>
                <Pages>
                  <li>
                    <Link
                      color="neutral"
                      onClick={() => handleInstitutionsSelect()}
                      activeStyle={activeLinkStyle}
                      to="/instituicoes"
                    >
                      <FormattedMessage id="institutions" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      color="neutral"
                      onClick={() => handleCampaignsSelect()}
                      activeStyle={activeLinkStyle}
                      to="/campanhas"
                    >
                      <FormattedMessage id="campaigns" />
                    </Link>
                  </li>
                </Pages>
                <hr />
              </Fragment>
            )}
            <Actions>
              {!isCheckout && (
                <li>
                  <Link to="/instituicoes/cadastrar">
                    <Button
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.97 }}
                      color="primary"
                      uppercase
                    >
                      <FormattedMessage id="register_institution" />
                    </Button>
                  </Link>
                </li>
              )}
              <li>
                {logged ? (
                  <div
                    css={css`
                      position: relative;
                    `}
                  >
                    <UserOptions onClick={() => setShowNav(!showNav)}>
                      <Text color="arsenic">
                        <FormattedMessage id="menu.greeting" />,{' '}
                        <Text as="span" color="celestialblue" weight="bold">
                          {user.profile.name.split(' ')[0]}
                        </Text>
                      </Text>
                      <Arrow />
                    </UserOptions>
                    <NavMenuDesktop
                      css={css`
                        ${!showNav && `height: 0`};
                        ${!showNav && `opacity: 0`};
                      `}
                    >
                      <li>
                        <Link to="/perfil">
                          <FormattedMessage id="menu.edit_profile" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/perfil/doacoes">
                          <FormattedMessage id="menu.my_donations" />
                        </Link>
                      </li>
                      <li>
                        <button type="button" onClick={handleLogoff}>
                          <FormattedMessage id="menu.exit" />
                        </button>
                      </li>
                    </NavMenuDesktop>
                  </div>
                ) : (
                  <Link to="/login">
                    <Button
                      onClick={handleLogin}
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.97 }}
                      color="secondary"
                      uppercase
                    >
                      <FormattedMessage id="login" />
                    </Button>
                  </Link>
                )}
              </li>
            </Actions>
          </Nav>

          <NavMobile>
            <button
              type="button"
              onClick={() => {
                setShowNav(!showNav)
              }}
            >
              <Img fixed={data.hamburguer.childImageSharp.fixed} />
            </button>
            <NavMenuMobile
              css={css`
                ${!showNav && `height: 0`};
                ${!showNav && `opacity: 0`};
                ${!showNav && `padding: 0 46px`};
              `}
            >
              <Pages>
                {!isCheckout && (
                  <Fragment>
                    <li>
                      <Link
                        onMouseDown={() => handleInstitutionsSelect()}
                        onTouchStart={() => handleInstitutionsSelect()}
                        activeStyle={activeLinkStyle}
                        to="/instituicoes"
                      >
                        <FormattedMessage id="institutions" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseDown={() => handleCampaignsSelect()}
                        onTouchStart={() => handleCampaignsSelect()}
                        activeStyle={activeLinkStyle}
                        to="/campanhas"
                      >
                        <FormattedMessage id="campaigns" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/instituicoes/cadastrar"
                        activeStyle={activeLinkStyle}
                      >
                        <FormattedMessage id="register_institution" />
                      </Link>
                    </li>
                  </Fragment>
                )}
                {logged && (
                  <li>
                    <Link to="/perfil/doacoes" activeStyle={activeLinkStyle}>
                      <FormattedMessage id="menu.my_donations" />
                    </Link>
                  </li>
                )}
                {logged && (
                  <li>
                    <Link to="/perfil" activeStyle={activeLinkStyle}>
                      <FormattedMessage id="menu.edit_profile" />
                    </Link>
                  </li>
                )}
              </Pages>
              <div>
                <hr />
                {logged ? (
                  <Fragment>
                    <Text color="arsenic" weight="300">
                      <FormattedMessage id="menu.greeting" />,{' '}
                      <Text as="span" color="celestialblue" weight="bold">
                        {user.profile.name.split(' ')[0]}
                      </Text>
                    </Text>
                    <button
                      type="button"
                      className="logoff"
                      onClick={handleLogoff}
                    >
                      <FormattedMessage id="menu.exit" />
                    </button>
                  </Fragment>
                ) : (
                  <Link to="/login">
                    <Button
                      onClick={handleLogin}
                      color="primary"
                      uppercase
                      stretch
                    >
                      <FormattedMessage id="login" />
                    </Button>
                  </Link>
                )}
              </div>
            </NavMenuMobile>
          </NavMobile>

          <div
            css={theme => css`
              ${theme.queries.small} {
                margin: 0 20px 0 auto;
              }

              ${theme.queries.large} {
                transform: translateX(-70px);
                z-index: 4;
              }
            `}
          >
            <ChooseLanguage />
          </div>
        </NavBar>
      </Wrapper>
    </Container>
  )
}

export default Header
